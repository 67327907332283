<template>
    <v-data-table :headers="headers" :items="items" sort-by="order" :items-per-page="5" class="mb-5">
        <template v-slot:top>
        <v-toolbar flat color="white">
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <div class="flex-grow-1"></div>

            <v-dialog v-model="dialog" max-width="400px">
            <template v-slot:activator="{ on }">
                <v-btn color="primary" class="mt-5" v-on="on" :disabled="newItemDisabled">New Item</v-btn>
            </template>
            
            <v-card>
                <v-card-title>
                <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                <v-container>
                    <v-row>
                    <div class="ml-2 mb-2" style="display: flex; width: 100%;">
                        <input type="file" style="display: none;" ref="fileInput" accept="image/png, image/jpeg" @change="fileSelected">
                        <v-btn color="primary darken-1" text @click="selectFile">Select Image</v-btn>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text class=" align-end justify-end" @click="clearFileInput">Clear</v-btn>
                    </div>
                    <v-col class="padding-input" style="height: 100px;">
                        <img :src="editedItem.url" height="100"/>
                    </v-col>
                    <v-col v-if="lines >= 1" cols="12" class="padding-input">
                        <v-text-field v-model="editedItem.line1" label="Line1"></v-text-field>
                    </v-col>
                    <v-col v-if="lines >= 2" cols="12" class="padding-input">
                        <v-text-field v-model="editedItem.line2" label="Line2"></v-text-field>
                    </v-col>
                    <v-col v-if="lines >= 3" cols="12" class="padding-input">
                        <v-text-field v-model="editedItem.line3" label="Line3"></v-text-field>
                    </v-col>
                    <v-col cols="12" class="padding-input">
                        <v-text-field v-model="editedItem.width" label="Width (for inline images, 12 total)"></v-text-field>
                    </v-col>
                    <v-col cols="12" class="padding-input">
                        <v-text-field v-model="editedItem.order" label="Order"></v-text-field>
                    </v-col>
                    </v-row>
                </v-container>
                </v-card-text>

                <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="primary darken-1" text @click="save" :disabled="!formIsValid">Save</v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
        <v-icon class="mr-3" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <template v-slot:item.url="{ item }">
          <img :src="item.url" class="mt-1 rounded-card" height="100"/>
        </template>
    </v-data-table>
</template>

<script>
export default {
  name: "AppImage",

  props: {
    title: {
      type: String,
      required: true
    },
    collection: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    lines: {
      type: Number,
      required: true
    },
    singleRecord: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    dialog: false,
    editedIndex: -1,
    editedItem: {
      line1: "",
      line2: "",
      line3: "",
      fileName: "",
      url: "",
      width: 0,
      order: 0,
      image: null
    },
    defaultItem: {
      line1: "",
      line2: "",
      line3: "",
      fileName: "",
      url: "",
      width: 0,
      order: 0,
      image: null
    },
    originalItem: {
      line1: "",
      line2: "",
      line3: "",
      fileName: "",
      url: "",
      width: 0,
      order: 0,
      image: null
    },
    formFieldsChanged: false,
    formImageChanged: false
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    headers() {
      let list = [
        {
          text: "Image",
          align: "left",
          sortable: false,
          value: "url"
        },
        { text: "Width", value: "width", width: 100 },
        { text: "Order", value: "order", width: 100 },
        { text: "", value: "action", sortable: false, width: 100 }
      ];

      if (this.lines == 0) {
        return list;
      } else if (this.lines == 1) {
        list.splice(1, 0, { text: "Line1", value: "line1" });
        return list;
      } else {
        list.splice(1, 0, { text: "Line1", value: "line1" });
        list.splice(2, 0, { text: "Line2", value: "line2" });
        list.splice(3, 0, { text: "Line3", value: "line3" });
        return list;
      }
    },
    formIsValid() {
      this.updateFormFieldsChanged();

      return (
        (this.formFieldsChanged || this.formImageChanged) &&
        this.editedItem.url != ""
      );
    },
    newItemDisabled() {
      let disabled = false;

      if (this.singleRecord) {
        if (
          this.items != null &&
          this.items != undefined &&
          this.items.length > 0
        ) {
          disabled = true;
        }
      }

      return disabled;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    selectFile() {
      this.$refs.fileInput.click();
    },
    clearFileInput() {
      this.editedItem.fileName = "";
      this.editedItem.url = "";
      this.editedItem.image = null;

      this.formImageChanged = true;
    },
    fileSelected(event) {
      const files = event.target.files;
      this.editedItem.image = files[0];

      this.$refs.fileInput.value = null;
      this.formImageChanged = true;

      this.$store.dispatch("getImageUrl", this.editedItem.image).then(
        response => {
          this.editedItem.url = response.url;
        },
        error => {
          console.error("response: ", error);
          this.clearFileInput();
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.originalItem = Object.assign({}, item);
      this.formImageChanged = false;

      this.$store.dispatch("getImageData", this.editedItem.url).then(
        response => {
          this.editedItem.image = response.image;
        },
        error => {
          console.error("response: ", error);
        }
      );

      this.dialog = true;
    },
    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$store
          .dispatch("deleteImage", {
            item: item,
            collection: this.collection
          })
          .then(
            () => {
              const index = this.items.indexOf(item);
              this.items.splice(index, 1);

              this.resetItem();
            },
            error => {
              console.error("response: ", error);
            }
          );
      }
    },
    close() {
      this.dialog = false;
      this.resetItem();
    },
    resetItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    save() {
      if (!this.editedItem.image) {
        return;
      }

      if (this.editedIndex > -1) {
        const index = this.editedIndex;

        this.$store
          .dispatch("updateImageWithWidth", {
            collection: this.collection,
            lines: this.lines,
            item: this.editedItem,
            formFieldsChanged: this.formFieldsChanged,
            formImageChanged: this.formImageChanged
          })
          .then(
            response => {
              Object.assign(this.items[index], response.item);
            },
            error => {
              console.error("response: ", error);
            }
          );
      } else {
        this.$store
          .dispatch("createImageWithWidth", {
            collection: this.collection,
            lines: this.lines,
            item: this.editedItem
          })
          .then(
            response => {
              this.items.push(response.item);
            },
            error => {
              console.error("response: ", error);
            }
          );
      }
      this.close();
    },
    updateFormFieldsChanged() {
      this.formFieldsChanged =
        this.editedItem.line1 !== this.originalItem.line1 ||
        this.editedItem.line2 !== this.originalItem.line2 ||
        this.editedItem.line3 !== this.originalItem.line3 ||
        this.editedItem.width !== this.originalItem.width ||
        this.editedItem.order !== this.originalItem.order;
    }
  }
};
</script>

<style scoped>
.padding-input {
  padding: 0 10px;
}

.rounded-card {
  border-radius: 5px;
}
</style>